//Actions related to login, logout to Application
export const ACCOUNT__LOGIN = createActionType('ACCOUNT__LOGIN');
export const ACCOUNT__LOGOUT = createActionType('ACCOUNT__LOGOUT');

//define laboratory action type
export const ALL_REPORT_LIST = createActionType('ALL_REPORT_LIST');
export const ALL_TEST_LIST = createActionType('ALL_TEST_LIST');
export const CREATE_PATIENT = createActionType('CREATE_PATIENT');
export const CREATE_PATIENT_LAB_REPORT = createActionType('CREATE_PATIENT_LAB_REPORT');
export const UPDATE_PATIENT_LAB_REPORT = createActionType('UPDATE_PATIENT_LAB_REPORT');
export const PATIENT_LIST = createActionType('PATIENT_LIST');
export const PATIENT_REPORT_BY_ID = createActionType('PATIENT_REPORT_BY_ID');
export const PATIENT_REPORT_BY_ID_FORMATTED = createActionType('PATIENT_REPORT_BY_ID_FORMATTED');
export const UPDATE_PATIENT_REPORT_RESULT = createActionType('PATIENT_REPORT_BY_ID_FORMATTED');

export function createAction({ action, headers = {}, type }) {
  return async (dispatch) => {
    dispatch({ type: type.REQUEST, headers });
    try {
      dispatch({ type: type.SUCCESS, headers, payload: await action() });
    } catch (error) {
      dispatch({ type: type.FAILURE, headers, error });
    }
  };
}
export function createActionType(id) {
  return {
    FAILURE: `${id}__FAILURE`,
    REQUEST: `${id}__REQUEST`,
    SUCCESS: `${id}__SUCCESS`,
  };
}