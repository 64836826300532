import baseState from '../store/baseState';
import { ACCOUNT__LOGIN, ACCOUNT__LOGOUT } from '../actions/action';
export default (state = baseState.authData, { payload, type, error }) => {
  switch (type) {
    case ACCOUNT__LOGIN.REQUEST:
      return {
        ...state,
        authenticated: false
      };
    case ACCOUNT__LOGIN.SUCCESS:
      if (payload.data.statusCode === 401) {
        console.log("Authen");
        return {
          ...state,
          loginDetails: {},
          authenticated: false
        };
      } else {
        console.log("Authen 2");
        return {
          ...state,
          loginDetails: payload.data,
          authenticated: true
        };
      }
    case ACCOUNT__LOGIN.FAILURE:
      return {
        ...state,
        loginDetails: {},
        error: error.message,
        authenticated: false
      };
    case ACCOUNT__LOGOUT.SUCCESS:
      return {
        ...state,
        loginDetails: {},
        authenticated: false,
      };
    default:
      return state;
  }
};