export default {
  authData: {
    loginDetails: {},
    authenticated: false,
    error: ''
  },

  labortoryData: {
    patientReportList: [],
    labTestAllList: [],
    createdPatient: {},
    patientLabReport: {},
    patientLabReportUpdate: {},
    patientList: [],
    patientLabReport: [],
    patientLabReportFormatted: {},
    patientReportResult: {}
  }

}