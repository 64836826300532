import baseState from '../store/baseState';
import {
  ALL_REPORT_LIST, ALL_TEST_LIST, CREATE_PATIENT, CREATE_PATIENT_LAB_REPORT, UPDATE_PATIENT_LAB_REPORT, PATIENT_LIST, PATIENT_REPORT_BY_ID, PATIENT_REPORT_BY_ID_FORMATTED,
  UPDATE_PATIENT_REPORT_RESULT
} from '../actions/action';

export default (state = baseState.labortoryData, { payload, type, error }) => {
  switch (type) {

    case ALL_REPORT_LIST.REQUEST:
      return {
        ...state,
      };

    case ALL_REPORT_LIST.SUCCESS:
      return {
        ...state,
        patientReportList: payload.data
      };

    case ALL_TEST_LIST.REQUEST:
      return {
        ...state,
      };

    case ALL_TEST_LIST.SUCCESS:
      return {
        ...state,
        labTestAllList: payload.data
      };

    case CREATE_PATIENT.REQUEST:
      return {
        ...state,
      };

    case CREATE_PATIENT.SUCCESS:
      return {
        ...state,
        createdPatient: payload.data
      };

    case CREATE_PATIENT_LAB_REPORT.REQUEST:
      return {
        ...state,
      };

    case CREATE_PATIENT_LAB_REPORT.SUCCESS:
      return {
        ...state,
        patientLabReport: payload.data
      };

    case UPDATE_PATIENT_LAB_REPORT.REQUEST:
      return {
        ...state,
      };

    case UPDATE_PATIENT_LAB_REPORT.SUCCESS:
      return {
        ...state,
        patientLabReportUpdate: payload.data
      };

    case PATIENT_LIST.REQUEST:
      return {
        ...state,
      };

    case PATIENT_LIST.SUCCESS:
      return {
        ...state,
        patientList: payload.data
      };

    case PATIENT_REPORT_BY_ID.REQUEST:
      return {
        ...state,
      };

    case PATIENT_REPORT_BY_ID.SUCCESS:
      return {
        ...state,
        patientLabReport: payload.data
      };

    case PATIENT_REPORT_BY_ID_FORMATTED.REQUEST:
      return {
        ...state,
      };

    case PATIENT_REPORT_BY_ID_FORMATTED.SUCCESS:
      return {
        ...state,
        patientLabReportFormatted: payload.data
      };

    case UPDATE_PATIENT_REPORT_RESULT.REQUEST:
      return {
        ...state,
      };

    case UPDATE_PATIENT_REPORT_RESULT.SUCCESS:
      return {
        ...state,
        patientReportResult: payload.data
      };

    default:
      return state;
  }
};