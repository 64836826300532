import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from './common/Spinner';
import { isAuthenticated } from '../config/appSession';

const Login = lazy(() => import('./user/Login'));
const ReportManagement = lazy(() => import('./product/ReportManagement'));

class AppRoutes extends Component {

  state = {
    userToken: {},
    user: "",
    token: false
  }

  componentDidMount() {
    this.getToken();
    this.getUserRole();
  }

  getToken = () => {
    if (isAuthenticated() === true) {
      this.setState({
        token: true
      })
    }
  }

  getUserRole = () => {
    let user = localStorage.getItem("userRole");
    this.setState({ user: user })
  }

  render() {

    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/user/login" component={Login} />
          <Route exact path="/user/reports" component={ReportManagement} />
          {isAuthenticated() ?
            <Redirect to="/user/reports" /> :
            <Redirect to="/user/login" />
          }
        </Switch>

      </Suspense>
    );
  }
}


export default AppRoutes;